import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../components/layout";

const ContactUs = () => {
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSubmit = async (values, { resetForm }) => {
        const baseURL = process.env.REACT_APP_API_URL;
        console.log('Form Values:', values);
        console.log('Base URL:', { baseURL });

        setLoading(true); // Set loading to true

        try {
            const response = await axios.post(`${baseURL}/cms/contact-us`, values, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message);
            resetForm();
        } catch (error) {
            console.error('Error submitting contact form', error);
            alert('There was an error submitting your form. Please try again later.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <>
            <Layout>
                <div className="innerBan">
                    <img src="assets/images/innerbnr1.png" className="w-100" alt="" />
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="reachSection">
                    <div className="container">
                        <div className="head-reach text-center mb-5">
                            <h2 className="mainHead">Contact Form</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="reachBox">
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            email: '',
                                            phone: '',
                                            message: '',
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ handleChange }) => (
                                            <Form className="reachForm">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Name *</label>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Email Address *</label>
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Phone Number *</label>
                                                            <Field
                                                                type="text"
                                                                name="phone"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Message *</label>
                                                            <Field
                                                                as="textarea"
                                                                name="message"
                                                                className="form-control"
                                                                rows="6"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="themeBtn"
                                                        >
                                                            Send Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Full-Screen Loader */}
                {loading && (
                    <div className="fullscreen-loader">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                )}
            </Layout>
            <ToastContainer />
        </>
    );
};

export default ContactUs;
