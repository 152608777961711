import React, {useEffect, useState} from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { login, register, logout } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/layout";

import 'react-modern-drawer/dist/index.css'
import Search from "../../components/search";
import axios from "axios";


const EmergencyServices = () => {
    const [emergencyServices, setEmergencyServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEmergencyServices = async () => {
            try {
                const response = await axios.get(`${baseURL}/emergency-service/emergency-service`);
                setEmergencyServices(response.data); // Set the fetched data to state
                setLoading(false); // Stop loading when data is fetched
            } catch (error) {
                console.error('Error fetching emergency services:', error);
                setLoading(false); // Stop loading even if there is an error
            }
        };

        fetchEmergencyServices();
    }, [baseURL]);
    return (
        <>
            <Layout>
                <div className="innerBan">
                    <img src="assets/images/innerbnr1.png" className="w-100" alt="" />
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center">
                                    <h2>Emergency Services</h2>
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="emergency-sec">
                    <div className="container">
                        <h2 className="mainHead text-center mb-5">Emergency Services</h2>
                        <div className="row align-items-center">
                            {emergencyServices.map((service, index) => (
                            <div className="col-md-12">
                                <div className="emergency-wrap">
                                    <figure>
                                        <img src={`${baseURL}/uploads/emergency-service/${service.image}`} className="img-fluid" alt={service.title} />
                                    </figure>
                                    <div className="emergency-cntnt">
                                        <h3>{service.title}</h3>
                                        <h5> <i className="far fa-clock"></i> Wait Times (if available) <span>Morning 11: (am) to Night
                                                10: (pm)</span> </h5>
                                        <a href="" className="themeBtn">Contact for Emergency Services</a>
                                    </div>
                                </div>
                            </div>
                            ))}
                            {/*<div className="col-md-12">
                                <div className="emergency-wrap">
                                    <figure>
                                        <img src="assets/images/emergency2.png" className="img-fluid" alt="" />
                                    </figure>
                                    <div className="emergency-cntnt">
                                        <h3>Emergency Room Details</h3>
                                        <h5> <i className="far fa-clock"></i> Wait Times (if available) <span>Morning 11: (am) to Night
                                                10: (pm)</span> </h5>
                                        <a href="" className="themeBtn">Contact for Emergency Services</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="emergency-wrap">
                                    <figure>
                                        <img src="assets/images/emergency3.png" className="img-fluid" alt="" />
                                    </figure>
                                    <div className="emergency-cntnt">
                                        <h3>Emergency Room Details</h3>
                                        <h5> <i className="far fa-clock"></i> Wait Times (if available) <span>Morning 11: (am) to Night
                                                10: (pm)</span> </h5>
                                        <a href="" className="themeBtn">Contact for Emergency Services</a>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default EmergencyServices;