import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter,Route,Routes,HashRouter} from "react-router-dom";
import ProtectedRoutes from './utils/protectedRoutes';
import Home from './pages/home';
import AboutUs from './pages/about-us';
import HospitalDirectory from './pages/hospital-directory';
import EmergencyServices from './pages/emergency-services';
import ContactUs from './pages/contact-us';
import Faqs from './pages/faqs';

// admin routes
import AdminDashboard from './admin/dashboard';

function App() {
  return (
    <>
      <ToastContainer theme="colored" position="top-right" />

      <HashRouter 
        // basename={process.env.REACT_APP_BASE_NAME}
      >
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/hospital-directory" element={<HospitalDirectory/>}/>
          <Route path="/emergency-services" element={<EmergencyServices/>}/>
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/faqs" element={<Faqs/>}/>

          {/* admin routes */}
          <Route path="/admin" element={<ProtectedRoutes><AdminDashboard/></ProtectedRoutes>} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;