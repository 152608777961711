import Header from "../header";
import Footer from "../footer";
import Preloader from "./../../components/preloader";

const Layout = ({children}) => {
    return (
        <>
            {/* <Preloader/> */}

            <Header/>
                {children}
            <Footer/>
        </>
    );
}

export default Layout;