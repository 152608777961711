import { AxiosInstance } from '../../config/axios';
import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from './types/authType';

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = (data) => ({ type: FETCH_DATA_SUCCESS, payload: data });
export const fetchDataFailure = (error) => ({ type: FETCH_DATA_FAILURE, payload: error });

export const login = (payload) => {
  return async (dispatch) => {
      dispatch(fetchDataRequest());
        try {
          const response = await AxiosInstance.post('login', payload);

          dispatch(fetchDataSuccess(response?.data?.data?.data));
        } catch (error) {
          dispatch(fetchDataFailure(error.message));
        }
  };
};

export const register = (payload) => {
  return async (dispatch) => {
      dispatch(fetchDataRequest());
        try {
          const response = await AxiosInstance.post('register', payload);

          dispatch(fetchDataSuccess(response?.data?.data?.data));
        } catch (error) {
          dispatch(fetchDataFailure(error.message));
        }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await AxiosInstance.post('logout');

      dispatch(fetchDataSuccess({payload: { token: null, user: null }}));
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
}