// rootReducer.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import imageZoneReducer from './imageZoneReducer';
import ipAddressReducer from './ipAddressReducer';

const rootReducer = combineReducers({
    authReducer,
    imageZoneReducer,
    ipAddress: ipAddressReducer
});

export default rootReducer;
