import axios from 'axios';
import store from './../../store';
// import { setLoading } from 'store/redux/slices/user';
import { showSuccess, showError } from './../../utils/toast';

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { }
});

const getToken = () => {
    const state = store.getState();
    return state.authReducer.token;
};

AxiosInstance.interceptors.request.use(async config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    
    // store.dispatch(setLoading(true));
    return config;
});

AxiosInstance.interceptors.response.use(response => {
    if(response?.data?.data?.toast) showSuccess(response?.data?.message);
    // store.dispatch(setLoading(false));
    
    return response;
}, error => {
    // store.dispatch(setLoading(false));
    showError(error.response?.data?.message ?? error.message);
});

export { AxiosInstance };