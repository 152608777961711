import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Assuming you have your rootReducer
import authMiddleware from './middlewares/authMiddleware';

const store = configureStore({
  reducer: rootReducer,
  // Only add your custom middleware here
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
});

export default store;
