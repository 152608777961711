import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DistanceCalculator = ({ hospitalLatitude, hospitalLongitude }) => {
    const [distance, setDistance] = useState(null);
    const [userCoords, setUserCoords] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coords = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    setUserCoords(coords);
                    calculateDistance(coords);
                },
                (err) => {
                    setError('Unable to retrieve location');
                    console.error(err);
                }
            );
        } else {
            setError('Geolocation not supported by this browser');
        }
    }, []);

    const calculateDistance = async (userCoords) => {
        if (!userCoords) {
            setError('User coordinates not available');
            return;
        }

        const apiKey = 'AIzaSyAAlsWW4P_jWEKbCBfW64LRQq_ZgPX8zA8 ';
        const origin = `${userCoords.latitude},${userCoords.longitude}`;
        const destination = `${hospitalLatitude},${hospitalLongitude}`;

        const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;

        try {
            const response = await axios.get(url);
            const result = response.data;

            if (result.rows[0].elements[0].status === 'OK') {
                const distanceInMeters = result.rows[0].elements[0].distance.value;
                setDistance(distanceInMeters / 1000);
            } else {
                setError('Unable to calculate distance');
            }
        } catch (error) {
            setError('Error fetching distance data');
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <h4>Distance:{error && <p>{error}</p>}
                {distance && <p>Distance: {distance} km</p>}
            </h4>

        </div>
    );
};

export default DistanceCalculator;
