import { useState,useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import footerlogo from './../../assets/images/footerlogo.webp';
import call from './../../assets/images/call.webp';
import mail from './../../assets/images/mail.webp';
import pin from './../../assets/images/pin.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allSliders, mouseCursor, replaceHash, imgRevealer, headingAnimation, lenissetup } from './../../assets/js/custom.min.js';
import axios from "axios";

const Footer = () => {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL;

    const handleSubscriber = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}subscriber/subscribe/`,{email})
            toast.success(response.data.message);
            setEmail('');
        }catch (error){
            console.log(error);
            toast.error('Failed to subscribe');
        }

    }

    // useEffect(() => {
    //     let scriptLoaded = false;

    //     const loadCustomMinJs = async () => {
    //         AOS.init();
    //     };

    //     loadCustomMinJs();

    //     // Cleanup function
    //     return () => {};
    // }, [location])

    useEffect(() => {
        // import('./../../assets/js/all.min.js');
        
        allSliders()
        mouseCursor()
        replaceHash()
        imgRevealer()
        headingAnimation()
        lenissetup()
        // preLoader()
        // initializeFunctions()
    }, [location]);

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-8" data-aos="fade-up">
                            <h2>Need Help? Quick Contact now</h2>
                        </div>
                        <div className="col-md-3" data-aos="fade-up">
                            <div className="contactcl">
                                <a href=""> <img src="assets/images/contactcll.webp" className="img-fluid" alt="" /> +0123-4567-890</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-3">
                            <a href="#" className="footerlogo"><img src={footerlogo} className="img-fluid" alt="" /></a>
                        </div>

                        <div className=" col-md-2">
                            <h3>Quick Links:</h3>
                            <ul className="links">
                                <li>
                                    <NavLink to="/">
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about-us">
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hospital-directory">
                                        Hospital Directory
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/emergency-services">
                                        Emergency Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact-us">
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faqs">
                                        FAQs
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className=" col-md-3">
                            <h3>Contact information</h3>
                            <ul className="links links2">
                                <li><a href="#"> <img src={call} className="img-fluid" alt="" /> +012-3456-7890</a></li>
                                <li><a href="#"><img src={mail} className="img-fluid" alt="" /> info@gmail.com</a></li>
                                <li><a> <img src={pin} className="img-fluid" alt="" /> Get Location on google map</a></li>
                            </ul>
                        </div>
                        <div className=" col-md-4">
                            <h3>Sign Up For Our News</h3>

                                <form className="footForm" onSubmit={handleSubscriber}>
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <button type="submit" className="themeBtn themeBtn1 btn">Sign up</button>
                                </form>
                            {message && <p>{message}</p>}
                            <ul className="socialLinks">
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row align-items-center copyRight">
                        <div className="col-md-6 ">
                            <p>Copyright© 2024. All rights reserved.</p>
                        </div>
                        <div className="col-md-6">
                            <ul className="copyrightlist">
                                <li>Privacy Policy </li>
                                <li> | </li>
                                <li> Terms of Service</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;