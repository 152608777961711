import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store'; // Import your Redux store
import { ConfirmAlertProvider } from 'react-use-confirm-alert';

// required styles
import './assets/css/all.min.css';
import './assets/css/custom.min.css';
import './assets/css/responsive.css';

// requried scripts
import jQuery from 'jquery';
import Swiper from 'swiper';
import Lenis from '@studio-freight/lenis';
import AOS from 'aos';
import gsap from 'gsap';
import 'bootstrap/dist/js/bootstrap.bundle.min';

window.jQuery = jQuery;
window.Swiper = Swiper;
window.Lenis = Lenis;
window.AOS = AOS;
window.gsap = gsap;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfirmAlertProvider>
        <App />
      </ConfirmAlertProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
