import React, {useEffect, useState, useRef} from 'react';
import Layout from "../../components/layout";
import axios from 'axios'; // Import axios
import 'react-modern-drawer/dist/index.css'

import Search from "../../components/search";


import {Swiper, SwiperSlide} from 'swiper/react';
// import {Navigation} from 'swiper';
import {Navigation} from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // Import Swiper styles

// Import Swiper React components
// import {Swiper, SwiperSlide} from "swiper/swiper-react";

// import {Navigation} from 'swiper/modules';
// import required modules


const Home = () => {
    const [emergencyServices, setEmergencyServices] = useState([]);
    const [keyFeatures, setKeyFeatures] = useState([]); // State to hold key features
    const [loading, setLoading] = useState(true); // State to handle loading
    const baseURL = process.env.REACT_APP_API_URL;
    // Fetch key features from the backend
    useEffect(() => {
        const fetchKeyFeatures = async () => {
            try {
                const response = await axios.get(`${baseURL}/key-feature/key-feature`);
                setKeyFeatures(response.data); // Set the fetched data to state
                setLoading(false); // Stop loading when data is fetched
            } catch (error) {
                console.error('Error fetching key features:', error);
                setLoading(false); // Stop loading even if there is an error
            }
        };

        fetchKeyFeatures();
    }, []);

    useEffect(() => {
        const fetchEmergencyServices = async () => {
            try {
                const response = await axios.get(`${baseURL}/emergency-service/emergency-service`);
                setEmergencyServices(response.data); // Set the fetched data to state
                setLoading(false); // Stop loading when data is fetched
            } catch (error) {
                console.error('Error fetching emergency services:', error);
                setLoading(false); // Stop loading even if there is an error
            }
        };

        fetchEmergencyServices();
    }, [baseURL]);

    return (
        <>
            <Layout>
                <section className="main-slider p-0" id="mainSlider">
                    <div className="swiper-container homeSlider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="slide-inner bg-image" data-background="assets/images/mainBnnr.webp">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="slideOne">
                                                    <h1 data-swiper-parallax="-200">Find the
                                                        Nearest <span>Hospital</span> in
                                                        Seconds</h1>
                                                    <p data-swiper-parallax="-200">Your trusted source for hospital and
                                                        emergency
                                                        room information across the USA</p>
                                                    <Search/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-inner bg-image" data-background="assets/images/mainBnnr.webp">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="slideOne">
                                                    <h1 data-swiper-parallax="-200">Find the
                                                        Nearest <span>Hospital</span> in
                                                        Seconds</h1>
                                                    <p data-swiper-parallax="-200">Your trusted source for hospital and
                                                        emergency
                                                        room information across the USA</p>
                                                    <Search/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-inner bg-image" data-background="assets/images/mainBnnr.webp">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="slideOne">
                                                    <h1 data-swiper-parallax="-200">Find the
                                                        Nearest <span>Hospital</span> in
                                                        Seconds</h1>
                                                    <p data-swiper-parallax="-200">Your trusted source for hospital and
                                                        emergency
                                                        room information across the USA</p>
                                                    <Search/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-inner bg-image" data-background="assets/images/mainBnnr.webp">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="slideOne">
                                                    <h1 data-swiper-parallax="-200">Find the
                                                        Nearest <span>Hospital</span> in
                                                        Seconds</h1>
                                                    <p data-swiper-parallax="-200">Your trusted source for hospital and
                                                        emergency
                                                        room information across the USA</p>
                                                    <Search/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="swiper-pagination"></div>

                    </div>
                </section>

                <section className="key-feature">
                    <div className="container">
                        <h2 className="mainHead text-center mb-3" data-aos="fade-up">Key Features</h2>
                        <div className="row align-items-center">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                keyFeatures.length > 0 ? (
                                    keyFeatures.map((feature, index) => (
                                        <div className="col-md-3" data-aos="fade-up" key={index}>
                                            <div className="feature-wrap">
                                                <figure>
                                                    {/* Assuming you store image URLs, else adjust as needed */}
                                                    <img
                                                        src={feature.image ? `${baseURL}/uploads/key-features/${feature.image}` : 'assets/images/default.webp'}
                                                        className="img-fluid" alt={feature.title}/>
                                                </figure>
                                                <div className="feeture-cntnt">
                                                    <h4>{feature.title}</h4>
                                                    {/*<p>{feature.description}</p>*/}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No key features available.</p>
                                )
                            )}

                        </div>
                    </div>
                </section>

                <section className="works-section">
                    <div className="container">
                        <h2 className="mainHead" data-aos="fade-up">How It Works</h2>
                        <div className="row align-items-center">
                            <div className="col-md-4" data-aos="fade-up">
                                <div className="workwrap">
                                    <span> 1</span>
                                    <figure>
                                        <img src="assets/images/works1.webp" className="img-fluid" alt=""/>
                                    </figure>
                                    <h4>Enter your location</h4>
                                </div>
                            </div>
                            <div className="col-md-4" data-aos="fade-up">
                                <div className="workwrap workwrap2">
                                    <span> 2</span>
                                    <figure>
                                        <img src="assets/images/works2.webp" className="img-fluid" alt=""/>
                                    </figure>
                                    <h4>View nearby hospitals</h4>
                                </div>
                            </div>
                            <div className="col-md-4" data-aos="fade-up">
                                <div className="workwrap workwrap3">
                                    <span> 3</span>
                                    <figure>
                                        <img src="assets/images/works3.webp" className="img-fluid" alt=""/>
                                    </figure>
                                    <h4>Get directions</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="patent-section">
                    <div className="container-fluid p-0" data-aos="fade-up">
                        <img src="assets/images/patentsec.webp" className="img-fluid" alt=""/>
                    </div>
                </section>

                <section className="services-section">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-8" data-aos="fade-up">
                                <div className="service-head">
                                    <h2 className="mainHead ">Emergency Services</h2>
                                    <div className="call">
                                        <img src="assets/images/callser1.webp" className="img-fluid" alt=""/>
                                        <a href=""><span>for quick access</span>+0123-4567-890</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <Swiper slidesPerView={3} spaceBetween={20} loop={true} navigation={true} modules={[Navigation]} className="serviceSlider">
                                {emergencyServices.map((service, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="servicewrap">
                                            <figure>
                                                <img
                                                    src={`${baseURL}/uploads/emergency-service/${service.image}`}
                                                    className="img-fluid"
                                                    alt={service.title}
                                                />
                                            </figure>
                                            <div className="service-cntnt">
                                                <h4>{service.title}</h4>
                                                <p>{service.description}</p>
                                                <a href="contact.php" className="themeBtn">Contact us</a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </section>

                <section className="ourShop testimonialSec">
                    <div className="container">

                        <h2 className="mainHead text-center" data-aos="fade-up"> Testimonials</h2>
                        <div className="row">
                            <div className="swiper testimonialSlider" data-aos="fade-up">
                                <div className="swiper-wrapper">

                                    <div className="swiper-slide">
                                        <div className="testimonialBox">
                                            <div className="star">
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>

                                                </ul>
                                                <p>feb 7, 2024</p>
                                            </div>

                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem
                                                Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen
                                                book. </p>
                                            <div className="userWrap">
                                                <div className="userimg">
                                                    <img src="assets/images/user3.webp" className="img-fluid" alt=""/>
                                                    <h3>M. Martinez<span>Designation</span></h3>
                                                </div>
                                                <div className="quote">
                                                    <img src="assets/images/quote.webp" className="img-fluid" alt=""/>
                                                </div>
                                            </div>

                                            <a href="">Read Full Review <i className="far fa-long-arrow-right"></i></a>

                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonialBox">
                                            <div className="star">
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>

                                                </ul>
                                                <p>feb 7, 2024</p>
                                            </div>

                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem
                                                Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen
                                                book. </p>
                                            <div className="userWrap">
                                                <div className="userimg">
                                                    <img src="assets/images/user3.webp" className="img-fluid" alt=""/>
                                                    <h3>M. Martinez<span>Designation</span></h3>
                                                </div>
                                                <div className="quote">
                                                    <img src="assets/images/quote.webp" className="img-fluid" alt=""/>
                                                </div>
                                            </div>

                                            <a href="">Read Full Review <i className="far fa-long-arrow-right"></i></a>

                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonialBox">
                                            <div className="star">
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>

                                                </ul>
                                                <p>feb 7, 2024</p>
                                            </div>

                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem
                                                Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen
                                                book. </p>
                                            <div className="userWrap">
                                                <div className="userimg">
                                                    <img src="assets/images/user3.webp" className="img-fluid" alt=""/>
                                                    <h3>M. Martinez<span>Designation</span></h3>
                                                </div>
                                                <div className="quote">
                                                    <img src="assets/images/quote.webp" className="img-fluid" alt=""/>
                                                </div>
                                            </div>

                                            <a href="">Read Full Review <i className="far fa-long-arrow-right"></i></a>

                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonialBox">
                                            <div className="star">
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>

                                                </ul>
                                                <p>feb 7, 2024</p>
                                            </div>

                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem
                                                Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen
                                                book. </p>
                                            <div className="userWrap">
                                                <div className="userimg">
                                                    <img src="assets/images/user3.webp" className="img-fluid" alt=""/>
                                                    <h3>M. Martinez<span>Designation</span></h3>
                                                </div>
                                                <div className="quote">
                                                    <img src="assets/images/quote.webp" className="img-fluid" alt=""/>
                                                </div>
                                            </div>

                                            <a href="">Read Full Review <i className="far fa-long-arrow-right"></i></a>

                                        </div>
                                    </div>

                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default Home;