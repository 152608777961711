import React, { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { login, register, logout } from '../../store/actions';
import { useNavigate, NavLink } from 'react-router-dom';


import 'react-modern-drawer/dist/index.css'

import logo from './../../assets/images/logo.webp';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state?.authReducer?.token);
    

    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const loginFunc = (payload) => {
        dispatch(login(payload))
        .then(() => navigate('/uploads'))
        .catch((error) => console.error('Login failed:', error));
    }

    const registerFunc = (payload) => {
        dispatch(register(payload));
    }

    const logoutFunc = () => {
        dispatch(logout())
    }

    

    return (
        <>
            <div className="mouse-cursor cursor-outer"></div>
            <div className="mouse-cursor cursor-inner"></div>

            <header>
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container">
                        {/*<a className="navbar-brand" href="index.php">*/}
                            <NavLink className="nav-link" to="/">
                            <img src={logo} alt="img" />
                        {/*</a>*/}
                            </NavLink>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navMenu" aria-controls="navMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fas fa-bars"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navMenu">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <NavLink className="nav-link" to="/">
                                        Home <span className="sr-only">(current)</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about-us">
                                        About Us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/hospital-directory">
                                        Hospital Directory
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/emergency-services">
                                        Emergency Services
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-us">
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/faqs">
                                        FAQs
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;