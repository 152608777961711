import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const Search = () => {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/hospital-directory?search=${encodeURIComponent(search)}`);
    };

    return (
        <form className="mainForm" onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Enter your location or hospital name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <button type="submit" className="themeBtn">
                Search Now
            </button>
        </form>
    );
};

export default Search;
