import React, { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { login, register, logout } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/layout";

import 'react-modern-drawer/dist/index.css'
import Search from "../../components/search";


const AboutUs = () => {
    return (
        <>
            <Layout>
                <div className="innerBan">
                    <img src="assets/images/innerbnr1.png" className="w-100" alt="" />
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center">
                                    <h2>about us</h2>
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            

                <section className="about-page">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="aboutpagecntnt">
                                    <h2 className="mainHead">Our Mission</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                                        versions of Lorem Ipsum.
                                    </p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                                        versions of Lorem Ipsum.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <figure>
                                    <img src="assets/images/mission22.png" className="img-fludi" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="row align-items-center flex-row-reverse">
                            <div className="col-md-6">
                                <div className="aboutpagecntnt">
                                    <h2 className="mainHead">Our Vision</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                                        versions of Lorem Ipsum.
                                    </p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                                        versions of Lorem Ipsum.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <figure>
                                    <img src="assets/images/mission11.png" className="img-fludi" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="row align-items-center ">

                            <div className="col-md-12">
                                <div className="aboutpagecntnt">
                                    <h2 className="mainHead">Company History</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                                        versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                        an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                        has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                        software like Aldus PageMaker including versions of Lorem Ipsum.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <figure>
                                    <img src="assets/images/mission33.png" className="img-fludi" alt="" />
                                </figure>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="services-section">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-8" data-aos="fade-up">
                                <div className="service-head">
                                    <h2 className="mainHead ">Meet the Team</h2>

                                </div>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-up">
                            <div className="swiper serviceSlider">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">

                                        <div className="servicewrap">
                                            <figure>
                                                <img src="assets/images/team11.png" className="img-fludi" alt="" />
                                            </figure>
                                            <div className="meet-cntnt">
                                                <h3>Maria Henry</h3>
                                                <h4>Founder</h4>
                                                <ul className="socialLinks">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="swiper-slide">

                                        <div className="servicewrap">
                                            <figure>
                                                <img src="assets/images/team22.png" className="img-fludi" alt="" />
                                            </figure>
                                            <div className="meet-cntnt">
                                                <h3>Tim Abell</h3>
                                                <h4>CEO</h4>
                                                <ul className="socialLinks">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="swiper-slide">

                                        <div className="servicewrap">
                                            <figure>
                                                <img src="assets/images/team33.png" className="img-fludi" alt="" />
                                            </figure>
                                            <div className="meet-cntnt">
                                                <h3>Thomas Bradson</h3>
                                                <h4>Manager</h4>
                                                <ul className="socialLinks">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </section>

                <section className="services-section pressmedia">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-8" data-aos="fade-up">
                                <div className="service-head">
                                    <h2 className="mainHead ">Press and Media</h2>

                                </div>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-up">

                            <div className="col-md-4">
                                <div className="servicewrap">
                                    <figure>
                                        <img src="assets/images/services1.webp" className="img-fludi" alt="" />
                                    </figure>
                                    <div className="service-cntnt">
                                        <h5> <i className="fas fa-calendar-week"></i> Posted on May - 13 - 2024</h5>
                                        <h4>Title goes here</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                        <a href="" className="themeBtn">Contact us</a>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="servicewrap">
                                    <figure>
                                        <img src="assets/images/services2.webp" className="img-fludi" alt="" />
                                    </figure>
                                    <div className="service-cntnt">
                                        <h5> <i className="fas fa-calendar-week"></i> Posted on May - 13 - 2024</h5>
                                        <h4>Title goes here</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                        <a href="" className="themeBtn">Contact us</a>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="servicewrap">
                                    <figure>
                                        <img src="assets/images/services3.webp" className="img-fludi" alt="" />
                                    </figure>
                                    <div className="service-cntnt">
                                        <h5> <i className="fas fa-calendar-week"></i> Posted on May - 13 - 2024</h5>
                                        <h4>Title goes here</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                        <a href="" className="themeBtn">Contact us</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default AboutUs;