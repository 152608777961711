import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { login, register, logout } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/layout";
import 'react-modern-drawer/dist/index.css';
import axios from 'axios';
import Search from "../../components/search"; // For making the API call

const Faqs = () => {
    const [faqs, setFaqs] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch the FAQ data from the API
        const fetchFaqs = async () => {
            const baseURL = process.env.REACT_APP_API_URL;
            try {
                const response = await axios.get(`${baseURL}/faq/faq`); // Assuming this is the API endpoint
                setFaqs(response.data); // Update the state with the fetched data
            } catch (err) {
                setError('Failed to fetch FAQs');
                console.error('Error fetching FAQs:', err);
            }
        };

        fetchFaqs();
    }, []);

    return (
        <>
            <Layout>
                <div className="innerBan">
                    <img src="assets/images/innerbnr1.png" className="w-100" alt="" />
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center">
                                    <h2>FAQS</h2>
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="faqs-section">
                    <div className="container">
                        <div className="faqs-title">
                            <h3 className="mainHead">Frequently Asked Questions</h3>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div id="accordion">
                                    {error ? (
                                        <p>{error}</p>
                                    ) : (
                                        faqs.map((faq, index) => (
                                            <div className="card" key={index}>
                                                <div id={`heading${index}`}>
                                                    <button
                                                        className="btn btn-link collapsed"
                                                        data-toggle="collapse"
                                                        data-target={`#collapse${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        <h4 className="subHeading text-capitalize mb-0">
                                                            {faq.question}
                                                        </h4>
                                                        <i className="fas fa-plus"></i>
                                                    </button>
                                                </div>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`collapse`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-parent="#accordion"
                                                >
                                                    <div className="card-body">
                                                        <p>{faq.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Faqs;
