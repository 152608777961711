// action - state management
import { FETCH_IP_ADDRESS_DATA_SUCCESS, FETCH_IP_ADDRESS_DATA_REQUEST, FETCH_IP_ADDRESS_DATA_FAILURE } from './../actions/types/ipAddressType';

export const initialState = {
  ipAddress: null
};

// ==============================|| IP ADDRESS REDUCER ||============================== //

const ipAddressReducer = (state = initialState, action) => {
  console.log('action', action)
  switch (action.type) {
    case FETCH_IP_ADDRESS_DATA_REQUEST:
      return { ...state, error: null, loading: true };
    case FETCH_IP_ADDRESS_DATA_SUCCESS:
      return { ...state, ipAddress: action?.payload, loading: false  };
    case FETCH_IP_ADDRESS_DATA_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default ipAddressReducer;