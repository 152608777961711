const initialState = {
    loading: false,
    data: null,
    error: null
  };
  
  const imageZoneReducer = (state = initialState, action) => {
    console.log('action', action)
    switch (action.type) {
      case 'FETCH_IMAGE_ZONE_DATA_REQUEST':
        return { ...state, error: null, loading: true };
      case 'FETCH_IMAGE_ZONE_DATA':
        return { ...state, data: action?.payload, loading: false  };
      case 'FETCH_IMAGE_ZONE_DATA_FAILURE':
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };
  
  export default imageZoneReducer;