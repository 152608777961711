import { useEffect } from "react";
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

const ProtectedRoutes = ({children}) => {
    const token = useSelector((state) => state?.authReducer?.token);
    const location = useLocation();

    if(!token) return <Navigate to="/" state={{ from: location}} replace />;
    
    return children
};

export default ProtectedRoutes;