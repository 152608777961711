import React, { useEffect, useState, useCallback } from 'react';

// material-ui
import Grid from '@mui/material/Grid';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip, 
  Fab,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Badge
} from '@mui/material';
import { LoadScript, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from "../../components/layout";
import Search from "../../components/search";
import { useLocation } from 'react-router-dom';
import DistanceCalculator from "../../components/google_distance";
import { getIpAddress, requestForCreateHospital } from '../../store/actions';

const HospitalDirectory = () => {
    const [hospitals, setHospitals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');
    const [selectedHospital, setSelectedHospital] = useState(null);
    const [open, setOpen] = useState(false); // edit modal
    const [_open, _setOpen] = useState(false); // remove modal
    const [userLocation, setUserLocation] = useState(null);
    const [map, setMap] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [formValues, setFormValues] = useState({
        _id: null,
        title: '',
        address: '',
        longitude: '',
        latitude: '',
        image: null,
        // status: false,
        starttime: '',
        endtime: ''
    });
    const [errors, setErrors] = useState({});
    const { search: query } = useLocation();
    const dispatch = useDispatch();
    const { ipAddress } = useSelector((state) => state?.ipAddress);

    const baseURL = process.env.REACT_APP_API_URL;
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places'],
    });

    const handleSearch = (searchValue) => {
        fetchHospitals(searchValue);
    };

    const fetchHospitals = async (searchValue = '') => {
        setLoading(true);
        setError(null);

        try {
            const queryParams = new URLSearchParams();

            if (searchValue) queryParams.append('search', searchValue);
            if (filter) queryParams.append('filter', filter);

            const response = await fetch(`${baseURL}/cms/hospital?${queryParams.toString()}`, {
                headers: { 'accept': '*/*' }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setHospitals(data);
        } catch (error) {
            setError('Failed to load hospital data');
        } finally {
            setLoading(false);
        }
    };

    const fetchHospitalDetails = async (hospitalId) => {
        console.log('Fetching details for hospital ID:', hospitalId);
        const baseURL = process.env.REACT_APP_API_URL; // Set your API base URL

        try {
            const response = await fetch(`${baseURL}/cms/${hospitalId}/hospital`);
            if (!response.ok) {
                throw new Error('Failed to fetch hospital details');
            }

            const data = await response.json();
            setSelectedHospital(data);
        } catch (error) {
            console.error('Error fetching hospital details:', error);
        }
    };

    const handleProfileClick = (hospitalId) => {
        console.log('Clicked hospital ID:', hospitalId);
        fetchHospitalDetails(hospitalId);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = (place) => {
        const placeId = place?.value?.place_id;

        if (window.google && map) {
            const service = new window.google.maps.places.PlacesService(map);

            service.getDetails(
            {
                placeId: placeId,
                fields: ['geometry'],
            },
            (placeResult, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const lat = placeResult.geometry.location.lat();
                    const lng = placeResult.geometry.location.lng();
                    setUserLocation({ lat, lng });
                } else {
                    console.error('Error fetching place details:', status);
                }
            }
            );
        }
    };

    const handleClose = () => {
        setOpen(false);
        _setOpen(false);
        setSelectedRecord(null);
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const location = { lat: latitude, lng: longitude };
                    setUserLocation(location);
                },
                (error) => console.error('Error getting user location:', error)
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.title) newErrors.title = 'Title is required.';
        if (!formValues.address) newErrors.address = 'Address is required.';
        if (!formValues.longitude) newErrors.longitude = 'Longitude is required.';
        if (!formValues.latitude) newErrors.latitude = 'Latitude is required.';
        if (!formValues.image) newErrors.image = 'Image is required.';
        if (!formValues.starttime) newErrors.starttime = 'Start time is required.';
        if (!formValues.endtime) newErrors.endtime = 'End time is required.';
        return newErrors;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
            setFormValues((prev) => ({
                ...prev,
                image: reader.result,
            }));

            setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const dataURLtoBlob = (dataURL) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const resetFields = () => {
        setFormValues({
            title: '',
            address: '',
            longitude: '',
            latitude: '',
            starttime: '',
            endtime: '',
            image: null,
            // status: false,
        });
        setSelectedImage(null);
        setSelectedRecord(null);
        setErrors({});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const formData = new FormData();
            formData.append('title', formValues.title);
            formData.append('address', formValues.address);
            formData.append('longitude', formValues.longitude);
            formData.append('latitude', formValues.latitude);
            formData.append('starttime', formValues.starttime);
            formData.append('endtime', formValues.endtime);

            if (formValues?.image) {
                const blob = dataURLtoBlob(formValues.image);
                formData.append('image', blob, 'filename.jpg');
            }

            // formData.append('status', String(formValues.status));
            formData.append('ipaddress', String(ipAddress));
            
            await dispatch(requestForCreateHospital(formData));

            resetFields();
            handleClose();
        }
    };

    useEffect(() => {
        setLoading(false);
        getUserLocation();
        dispatch(getIpAddress());
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(query);
        const searchValue = params.get('search');

        if (searchValue) {
            fetchHospitals(searchValue);
        } else {
            fetchHospitals();
        }
    }, [query, filter]);

    useEffect(() => {
        setFormValues((prev) => ({ ...prev, latitude: userLocation?.lat }));
        setFormValues((prev) => ({ ...prev, longitude: userLocation?.lng }));
    }, [userLocation]);

    useEffect(() => {
        if( !open ) {
          setMap(null);
          resetFields();
        }
      }, [open]);
    

    useEffect(() => {
        console.log('ipAddress', ipAddress)
    }, [ipAddress]);

    const onMapLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        mapInstance.setZoom(14)
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;


    
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
            >
                <DialogTitle>Hospital</DialogTitle>

                <DialogContent 
                style={{
                    width: 600
                }}
                >
                <DialogContentText></DialogContentText>

                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="title"
                    name="title"
                    label="Title"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formValues.title}
                    onChange={handleChange}
                    error={!!errors.title}
                    helperText={errors.title}
                />

                <TextField
                    required
                    margin="dense"
                    id="address"
                    name="address"
                    label="Address"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formValues.address}
                    onChange={handleChange}
                    error={!!errors.address}
                    helperText={errors.address}
                />

                {open && (
                    <div style={{ height: '500px', width: '100%' }}>
                        <div style={{
                            marginTop: '25px'
                            }}>
                            <Typography>Search Location</Typography>
                        </div>
                        {/* <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={['places']}> */}
                            <GooglePlacesAutocomplete
                                selectProps={{
                                    onChange: handleSelect,
                                }}
                                fetchDetails={true}
                                onLoadFailed={(error) => console.error('Error loading Google Maps API:', error)}
                            />
                            {isLoaded && (
                                <GoogleMap
                                    id="map"
                                    onLoad={onMapLoad}
                                    mapContainerStyle={{ height: '400px', width: '100%' }}
                                    center={userLocation || { lat: 59.95, lng: 30.33 }}
                                    zoom={10}
                                    onClick={({ latLng }) => {
                                        const { lat, lng } = latLng.toJSON();
                                        setUserLocation({ lat, lng });
                                    }}
                                >
                                    {userLocation && (
                                        <Marker
                                        position={userLocation}
                                        onClick={() => {
                                            // Handle marker click if needed
                                            console.log('Marker clicked!', userLocation);
                                        }}
                                        />
                                    )}
                                </GoogleMap>
                            )}
                        {/* </LoadScript> */}
                    </div>
                )}

                <TextField
                    required
                    margin="dense"
                    id="latitude"
                    name="latitude"
                    label="Latitude"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formValues.latitude}
                    onChange={handleChange}
                    error={!!errors.latitude}
                    helperText={errors.latitude}
                    disabled
                />

                <TextField
                    required
                    margin="dense"
                    id="longitude"
                    name="longitude"
                    label="Longitude"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formValues.longitude}
                    onChange={handleChange}
                    error={!!errors.longitude}
                    helperText={errors.longitude}
                    disabled
                />
                    
                <div style={{ margin: '25px 0' }}>
                    <Typography>Upload Image</Typography>
                    <TextField
                    type="file"
                    inputProps={{ accept: 'image/*' }} // Restrict file types to images only
                    onChange={handleImageChange}
                    error={!!errors.image}
                    helperText={errors.image}
                    variant="standard"
                    />
                    {selectedImage && (
                    <div style={{ marginTop: '10px' }}>
                        <img src={selectedImage} alt="Selected" style={{ width: '200px', height: 'auto' }} />
                    </div>
                    )}
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Time"
                            id="starttime"
                            name="starttime"
                            value={formValues.starttime}
                            onChange={handleChange}
                            error={!!errors.starttime}
                            helperText={errors.starttime}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="End Time"
                            id="endtime"
                            name="endtime"
                            value={formValues.endtime}
                            onChange={handleChange}
                            error={!!errors.endtime}
                            helperText={errors.endtime}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit" variant="contained">Cancel</Button>
                    <Button
                        onClick={handleSubmit} 
                        // color="secondary" 
                        variant="contained"
                        className='themeBtn'
                    >
                        {'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        
            <Layout>
                <div className="innerBan">
                    <img src="assets/images/innerbnr1.png" className="w-100" alt="" />
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center">
                                    <h2>Hospital Directory</h2>
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="hospital-page">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-12">
                                <div className="hospitalhead">
                                    <h2 className="mainHead">Hospital Listings</h2>
                                    <div className="contactForm-group">
                                        <select
                                            id="sub"
                                            value={filter}
                                            onChange={(e) => setFilter(e.target.value)}
                                        >
                                            <option value="">Select Filter</option>
                                            <option value="specialty">Specialty</option>
                                            <option value="distance">Distance</option>
                                            <option value="rating">Rating</option>
                                            <option value="services">Services</option>
                                        </select>
                                        <a href="javascript:;" onClick={handleOpen} className='circle-btn ml-2'><i class="fa fa-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {hospitals.map((hospital) => (
                                <div key={hospital._id} className="col-md-4">
                                    <div className="hospitalwrap">
                                        <figure>
                                            <img src={`${baseURL}/${hospital.image}`} className="img-fluid" alt={hospital.title} />
                                        </figure>
                                        <div className="hospitalcntnt">
                                            <h3>{hospital.title}</h3>
                                            <div className="address">
                                                <img src="assets/images/locc.png" className="img-fluid" alt="location" />
                                                <h4>Address: <span>{hospital.address}</span></h4>
                                            </div>
                                            <div className="address">
                                                <img src="assets/images/diss.png" className="img-fluid" alt="distance" />
                                                {/*<h4>Longitude: <span>{hospital.longitude} km</span></h4>*/}
                                                {/*<h4>Ltiitude: <span>{hospital.latitude} km</span></h4>*/}
                                                {/*<DistanceCalculator
                                                    hospitalLatitude={hospital.latitude}
                                                    hospitalLongitude={hospital.longitude}
                                                />*/}
                                            </div>
                                            <ul className="starlist">
                                                <li> <span>Rating</span> </li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                            <h5>{hospital.services}</h5>
                                            <button onClick={() => handleProfileClick(hospital._id)} className="themeBtn">
                                                See Hospital Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {selectedHospital && (
                    <HospitalModal
                        hospital={selectedHospital}
                        onClose={() => setSelectedHospital(null)}
                    />
                )}

                <ToastContainer/>
            </Layout>
        </>
    );
};


const HospitalModal = ({ hospital, onClose }) => {
    if (!hospital) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}> {/* Prevent closing when clicking inside the modal */}
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Hospital Name: {hospital.title}</h2>
                <p>Hospital Address: {hospital.address}</p>
                <p>Distance: {hospital.distance} km</p>
                <ul className="starlistshow">
                    <li><span>Rating</span></li>
                    <li><i className="fas fa-star"></i></li>
                    <li><i className="fas fa-star"></i></li>
                    <li><i className="fas fa-star"></i></li>
                    <li><i className="fas fa-star"></i></li>
                    <li><i className="fas fa-star"></i></li>
                </ul>

            </div>
        </div>
    );
};

export default HospitalDirectory;
