// ALL SLIDERS

export function allSliders() {
  // MAIN HOME BANNER

  const swiperOptions = {
    loop: true,
    speed: 1000,
    parallax: true,
    autoplay: false,
    grabCursor: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  const homeSlider = new Swiper(".homeSlider", swiperOptions);

  const bgImages = document.querySelectorAll(".bg-image");
  bgImages.forEach((bgImage) => {
    const dataBackground = bgImage.getAttribute("data-background");
    if (dataBackground) {
      bgImage.style.backgroundImage = `url(${dataBackground})`;
    }
  });

  // OTHER SLIDERS

  var serviceSlider = new Swiper(".serviceSlider", {
    loop: true,
    autoplay: true,
    slidesPerView: 3,
    spaceBetween: 10,
    // centeredSlides: true,
    // roundLengths: true,
    // If we need pagination

    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      // type: 'fraction'
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });

  var testimonialSlider = new Swiper(".testimonialSlider", {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    // If we need pagination

    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      // type: 'fraction'
    },
    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });

  var logoSlider = new Swiper(".logoSlider", {
    loop: true,
    slidesPerView: 5,
    spaceBetween: 20,
    // If we need pagination

    breakpoints: {
      1024: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next2",
      prevEl: ".swiper-button-prev2",
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });
}

// GSAP PRELOADER

export function preLoader() {
  const tl = gsap.timeline({ duration: 0.1 });
  tl.to(".preLoader.black > img", {
    y: 50,
    autoAlpha: 0,
  })
    .to(".preLoader.black", {
      yPercent: -100,
    })
    .to(".preLoader.white", {
      yPercent: -100,
    })
    .to(".preLoader", {
      css: { display: "none" },
    })
    .from(".mouse", {
      y: -50,
      autoAlpha: 0,
    })
    .from(
      ".navbar-brand > img",
      {
        x: -50,
        autoAlpha: 0,
      },
      "-=1"
    )
    .from(
      ".navbar-nav > li, .form-inline a",
      {
        autoAlpha: 0,
        x: 100,
        stagger: 0.05,
      },
      "-=1"
    )
    .from(
      ".slideOne",
      {
        autoAlpha: 0,
        yPercent: 100,
        stagger: 0.05,
      },
      "-=1"
    );
}

//  CUSTOM MOUSE CURSOR

export function mouseCursor() {
  if (
    document.querySelector(".cursor-inner") ||
    document.querySelector(".cursor-outer")
  ) {
    const cursorInner = document.querySelector(".cursor-inner");
    const cursorOuter = document.querySelector(".cursor-outer");
    let mouseX = 0;
    let mouseY = 0;
    let isHovering = false;

    window.addEventListener("mousemove", function (event) {
      if (!isHovering) {
        gsap.to(cursorOuter, {
          x: event.clientX,
          y: event.clientY,
        });
      }
      gsap.to(cursorInner, {
        x: event.clientX,
        y: event.clientY,
      });
      mouseY = event.clientY;
      mouseX = event.clientX;
    });

    const elementsToTrack = document.querySelectorAll(
      "a, img, .cursor-pointer"
    );
    elementsToTrack.forEach((element) => {
      element.addEventListener("mouseenter", function () {
        cursorInner.classList.add("cursor-hover");
        cursorOuter.classList.add("cursor-hover");
        isHovering = true;
      });

      element.addEventListener("mouseleave", function () {
        if (
          !(
            element.tagName === "A" ||
            element.tagName === "IMG" ||
            element.closest(".cursor-pointer")
          )
        ) {
          cursorInner.classList.remove("cursor-hover");
          cursorOuter.classList.remove("cursor-hover");
        }
        isHovering = false;
      });
    });

    gsap.set(cursorInner, { visibility: "visible" });
    gsap.set(cursorOuter, { visibility: "visible" });
  }
}

// ANCHOR VOID MANIPULATE #

export function replaceHash() {
  document.querySelectorAll("a").forEach((a) => {
    let href = a.getAttribute("href");
    a.href = href
      ? href.startsWith("#") && href.endsWith("#")
        ? href.replace("#", "javascript:;")
        : href
      : "javascript:;";
  });
}

// GSAP IMAGE REVEAL ANIMATION

export function imgRevealer() {
  let revealContainers = document.querySelectorAll(".reveal");

  revealContainers.forEach((container) => {
    let image = container.querySelector("img");
    let revelerTl = gsap.timeline({
      scrollTrigger: {
        // scroller: '[data-scroll-container]',
        trigger: container,
        toggleActions: "restart none none reverse",
      },
    });

    revelerTl.set(container, { autoAlpha: 1 });
    revelerTl.from(container, 0.75, {
      xPercent: -100,
      ease: Power2.out,
    });
    revelerTl.from(image, 0.75, {
      xPercent: 100,
      scale: 1.3,
      delay: -0.75,
      ease: Power2.out,
    });
  });
}

// GSAP HEADING ANIMATION

export function headingAnimation() {
  const heading = document.querySelectorAll(".secHeading *");

  heading.forEach((text) => {
    const headingTl = gsap.timeline({
      scrollTrigger: {
        // scroller: '[data-scroll-container]',
        trigger: text,
        toggleActions: "play none none reset",
      },
    });
    headingTl.from(text, 1, {
      y: 120,
      autoAlpha: 0,
      ease: "power4.out",
    });
  });
}

// LENIS SETUP

export function lenissetup() {
  const lenis = new Lenis();
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);
}

// Function Initialization
export function initializeFunctions() {
  preLoader();
  allSliders();
  mouseCursor();

  replaceHash();

  // Animation EFFECTs
  imgRevealer();
  headingAnimation();

  // LENIS
  lenissetup();

  // AOS INIT
  AOS.init({
    duration: 1200,
    disable: "mobile",
  });
}

// Event listener for window load
window.addEventListener("load", initializeFunctions);
