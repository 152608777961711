import { toast } from 'react-toastify';
import { AxiosInstance } from '../../config/axios';
import { FETCH_IP_ADDRESS_DATA_SUCCESS, FETCH_IP_ADDRESS_DATA_REQUEST, FETCH_IP_ADDRESS_DATA_FAILURE } from './types/ipAddressType';

const fetchIpAddressDataRequest = () => ({ type: FETCH_IP_ADDRESS_DATA_REQUEST });
const fetchIpAddressDataSuccess = (data) => ({ type: FETCH_IP_ADDRESS_DATA_SUCCESS, payload: data });
const fetchIpAddressDataFailure = (error) => ({ type: FETCH_IP_ADDRESS_DATA_FAILURE, payload: error });

const BASE_URL = process.env.REACT_APP_API_URL;

export const getIpAddress = () => {
    return async (dispatch) => {
        dispatch(fetchIpAddressDataRequest());
        console.log("Request dispatched...");
        
        try {
            const response = await AxiosInstance.get('https://api.ipify.org?format=json');
            console.log("IP fetched:", response.data.ip);
            dispatch(fetchIpAddressDataSuccess(response.data.ip));
        } catch (error) {
            console.log("Error:", error);
            dispatch(fetchIpAddressDataFailure(error.message));
        }
    };
};

export const requestForCreateHospital = (payload) => {
    return async (dispatch) => {
        // dispatch(fetchIpAddressDataRequest());
        
        try {
            const response = await AxiosInstance.post(`${BASE_URL}/hospital/request-for-create-hospital`, payload);
            
            // dispatch(fetchIpAddressDataSuccess(response.data.ip));
            toast.success('Hospital request has been send to the admin!');
        } catch (error) {
            
            // dispatch(fetchIpAddressDataFailure(error.message));
            toast.error(error.message);
        }
    };
};

